import React from 'react'
import './Logo.css'
import { NavLink } from 'react-router-dom';

const Logo = () => {
  return (
    <>
        <p className='Logo2'>
        <NavLink 
            to="/" 
          >
                      SOURCEQOD

          </NavLink>        </p>
    </>
  )
}

export default Logo
