import React from 'react';
import './SectionName.css';

const SectionName = ({ sectionNames }) => {
  return (
    <div className='SectionName'>
      {sectionNames.map((name, index) => (
        <div key={index} className='section-item'>
          {name}
        </div>
      ))}
    </div>
  );
}

export default SectionName;
