import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import styled from 'styled-components';

const SlideshowContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  z-index: 111111111111111111111111111111111111111111111111111;
`;

const Slide = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; 
  z-index: 1111111111111111111111;
  height: 100%;
`;

const SlideImage = styled.img`
  width: 100%;
  height: 100%; 
  object-fit: cover;
  clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%);
`;

const BackgroundOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000; // Change this to your desired background color
  z-index: 111111111111111111111111111111111111111111111111110;
`;
const Slideshow = ({ images, onComplete }) => {
  const slidesRef = useRef([]);
  const backgroundRef = useRef(null);
  const [isAnimating, setIsAnimating] = useState(true);

  useEffect(() => {
    // Check if the URL contains "#service"
    if (window.location.hash.includes('#service') || window.location.hash.includes('#project') || window.location.hash.includes('#reviews')) {
      setIsAnimating(false);
      if (onComplete) onComplete();
      return;
    }

    const timeline = gsap.timeline({
      delay: 3,
      onComplete: () => {
        setIsAnimating(false);
        if (onComplete) onComplete();
      }
    });

    // Add background fade in
    timeline.to(backgroundRef.current, {
      opacity: 1,
      duration: 0.5,
    });

    slidesRef.current.forEach((slide, index) => {
      // Reveal the image
      timeline.to(slide, {
        clipPath: "polygon(100% 0%, 0% 0%, 0% 100%, 100% 100%)",
        duration: 3,
        ease: "power4.inOut",
      }, index * 0.25);

      // Move the image off-screen to the left
      timeline.to(slide, {
        xPercent: -100,
        duration: 1,
        ease: "power2.inOut",
      }, `>-0.5`);
    });

    // Add background fade out
    timeline.to(backgroundRef.current, {
      opacity: 0,
      duration: 0.5,
    });

    return () => {
      timeline.kill();
    };
  }, [onComplete]);

  if (!isAnimating) return null;

  return (
    <>
      <BackgroundOverlay ref={backgroundRef} style={{ opacity: 0 }} />
      <SlideshowContainer>
        {images.map((src, index) => (
          <Slide key={index}>
            <SlideImage
              src={src}
              alt={`Slide ${index + 1}`}
              ref={el => slidesRef.current[index] = el}
            />
          </Slide>
        ))}
      </SlideshowContainer>
    </>
  );
};

export default Slideshow;