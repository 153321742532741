import React from 'react';
import './PortfolioSection.css'; 
import app1 from '../../assets/2a6cf0de-11fb-45cd-b07d-d933d2f04d19.webp'
import app2 from '../../assets/32340.webp'
import app3 from '../../assets/475ed6d5-d3bb-4e84-ba89-f3602b7f4f6e.webp'
// import app4 from '../../assets/2a6cf0de-11fb-45cd-b07d-d933d2f04d19.webp'
import app4 from '../../assets/71f24a62-aabf-43c1-9bc7-cf2d8780d538.webp'

import SectionNameLight from '../SectionNameLight/SectionNameLight'
import { NavLink, useLocation } from 'react-router-dom';

const PortfolioSection = () => {
    const SectionNamesLight = ['OUR PROJECTS'];

  return (
    <div className="PortfolioSection Container2 Container">
        <div className='PortfolioSectionHeader'>
        <SectionNameLight SectionNamesLight={SectionNamesLight} />
        <p className="title">Elevating our Partners' Products with Unparalleled Value</p>
        </div>
      
      <div className="grid">
        <div className="product">
          <img loading="lazy" src={app1} alt="Hallo Helper App" className="productImage" />
          <div className="productContent">
            <h3 className="productTitle">FitZone</h3>
            <p className="productDescription">Here is a personal project for a workout equipment ecommerce store, with a large and diverse number of projects.</p>
            <div className="tags">
                <div>
                
                <span className="tag">Website</span>
                </div>

                <NavLink exact to="https://juulit.vercel.app/"> <button className="button">View detail</button></NavLink>
            </div>
          </div>
        </div>  <div className="product">
          <img loading="lazy" src={app2} alt="Hallo Helper App" className="productImage" />
          <div className="productContent">
            <h3 className="productTitle">JuuliT</h3>
            <p className="productDescription">Here's a redesign I've done on the JuuliT website, a website service that provides a complete solution for ip channels.</p>
            <div className="tags">
                <div>
                
                <span className="tag">Website</span>
                </div>
                <NavLink exact to="https://juulit.vercel.app/"> <button className="button">View detail</button></NavLink>
            </div>
          </div>
        </div>
        <div className="product">
          <img loading="lazy" src={app3} alt="Hallo Helper App" className="productImage" />
          <div className="productContent">
            <h3 className="productTitle">TVintent</h3>
            <p className="productDescription">TVIntent is a website dedicated to revolutionizing content delivery and distribution. we offer cutting-edge solutions that empower content creators and broadcasters.</p>
            <div className="tags">
                <div>
                
                <span className="tag">Website</span>
                </div>
                <button className="button">View detail</button>
            </div>
          </div>
        </div>
        <div className="product">
          <img loading="lazy" src={app4} alt="Hallo Helper App" className="productImage" />
          <div className="productContent">
            <h3 className="productTitle">Jade Novels</h3>
            <p className="productDescription">a novel website with a large collection or novels and books, provides users with multiple high quality novels</p>
            <div className="tags">
                <div>
                
                <span className="tag">Website</span>
                </div>
                <NavLink exact to="https://jadenovels.com/"> <button className="button">View detail</button></NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioSection;
