import React from 'react'
import Footer from '../../components/Footer/Footer'
import Navbar from '../../components/Navbar/Navbar'
import BlogCards from '../../components/BlogCards/BlogCards';

const Blog = () => {
    const blogPosts = [
        {
          image: 'path/to/image1.jpg',
          title: 'positive opinion about the writer or brand that produced the content.',
          description: 'Description of the blog post...'
        },
      ];
  return (
    <div>
      <Navbar />
      <BlogCards posts={blogPosts} />

      <Footer /> 
    </div>
  )
}

export default Blog
