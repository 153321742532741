import React, { useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import HeroImgSqr from '../../assets/blackpng.webp'
import HeroImg from '../../assets/2-1536x864.webp'
import CircleBtn from '../CircleBtn/CircleBtn'
import './HeroSection.css'
import Slideshow from '../Slidershow/Slidershow'
import LogoAnimation from '../LogoAnimation/LogoAnimation'

import img1 from '../../assets/testimgs/IMG_4166.webp';
import img2 from '../../assets/testimgs/IMG_4165.webp';
import img3 from '../../assets/testimgs/IMG_4164.webp'
import img4 from '../../assets/testimgs/IMG_4170.webp';

const HeroSection = () => {
  const loaderRef = useRef(null)
  const heroSectionRef = useRef(null)
  const [shouldAnimate, setShouldAnimate] = useState(true)

  useEffect(() => {
    // Check if the URL contains "#service"
    if (window.location.hash.includes('#service') || window.location.hash.includes('#project') || window.location.hash.includes('#reviews')) {
      setShouldAnimate(false)
      return
    }

    const tl = gsap.timeline()
  
    // Set initial states
    gsap.set(heroSectionRef.current, { opacity: 0 })
    gsap.set('.HeroSectionHeaderContainer', { y: 50, opacity: 0 })
    gsap.set('.HeroImg', { y: 100, opacity: 0 })
    gsap.set('.overlay', { opacity: 0 })
    gsap.set('.CircleBtnDiv', { scale: 0, opacity: 0 })
    gsap.set('.Corporation-P', { y: 30, opacity: 0 })
    gsap.set('.HeroImgSqr', { scale: 0.8, opacity: 0 })
  
    // Hero section animation
    tl.to(heroSectionRef.current, {
      opacity: 1,
      duration: 0.8,
      ease: 'power3.out'
    })
    .to('.HeroSectionHeaderContainer', {
      y: 0,
      opacity: 1,
      duration: 0.8,
      delay: 6
    })
    .to('.HeroImg', {
      y: 0,
      opacity: 1,
      duration: 0.8,
      delay: 0
    }, '-=0.8')
    .to('.overlay', {
      opacity: 1,
      duration: 0.8,
      delay: 0
    }, '-=0.8')
    .to('.CircleBtnDiv', {
      scale: 1,
      opacity: 1,
      duration: 0.8,
      ease: 'back.out(1.7)',
      delay: 0
    }, '-=0.5')
    .to('.Corporation-P', {
      y: 0,
      opacity: 1,
      duration: 0.8,
      delay: 0
    }, '-=0.3')
    .to('.HeroImgSqr', {
      scale: 1,
      opacity: 1,
      duration: 0.8,
      ease: 'power3.out',
      delay: 0
    }, '-=0.5')

    return () => {
      tl.kill()
    }
  }, [])

  const heroImages = [img1, img2, img3, img4];

  return (
    <>
      <LogoAnimation />
      {shouldAnimate && <Slideshow images={heroImages} />}

      <div className='HeroSection' ref={heroSectionRef} style={shouldAnimate ? {} : { opacity: 1 }}>
        <div className="overlay" style={shouldAnimate ? {} : { opacity: 1 }}></div>
        <div className="HeroSectionHeaderContainer" style={shouldAnimate ? {} : { opacity: 1, transform: 'translateY(0)' }}>
          <h1 className='HeroSectionHeader'>Experience our expert</h1>
          <h1 className='HeroSectionHeader HeroSectionHeader2'>digital solutions</h1>
          <div className='HeroSectionHeaderP'>
            <p className='HeroSectionHeaderP-Normal'>
              We are a digital agency that specializes in building and scaling cutting-edge digital
            </p>
            <p className='HeroSectionHeaderP-Normal'>
            Driving digital transformation through bespoke digital solutions and impactful branding.
            </p>
            <p className='HeroSectionHeaderP-Phone'>
     
            </p>
          </div>
          <div className='CircleBtnDiv' style={shouldAnimate ? {} : { opacity: 1, transform: 'scale(1)' }}>
            {/* <CircleBtn /> */}
          </div>
        </div>
        <img src={HeroImgSqr} loading="lazy" className='HeroImgSqr' alt="" style={shouldAnimate ? {} : { opacity: 1, transform: 'scale(1)' }} />
        <img src={HeroImg} loading="lazy" className='HeroImg' alt="" style={shouldAnimate ? {} : { opacity: 1, transform: 'translateY(0)' }} />
      </div>
    </>
  )
}

export default HeroSection