import React from 'react';
import './Footer.css'
import Logo from '../Logo/Logo'
const Footer = () => {
  return (  <>
    <footer className="footer  Container">
      <div className="footer-content">
        <div className="footer-logo">
          <Logo />
          <p className='footer-logo-p'>Location: Calicut, kerala, India 
          Zip : 673001</p>
        </div>
        <div className="footer-links">
          <div className="footer-section">
            <p>Navigation</p>
            <ul>
              <li>Home</li>
              <li>Service</li>
              <li>About</li>
              <li>Projects</li>
              <li>Blog</li>
            </ul>
          </div>
          {/* <div className="footer-section">
            <p>Products</p>
            <ul>
              <li>Iconin</li>
              <li>Pixperthub</li>
              <li>Envato</li>
              <li>Gumroad</li>
              <li>UI 8</li>
            </ul>
          </div> */}
          <div className="footer-section">
            <p>Socials</p>
            <ul>
              <li>LinkedIn</li>
              <li>Instagram</li>
              <li>Facebook</li>
              <li>Behance</li>
              <li>Dribbble</li>
            </ul>
          </div>
          <div className="footer-section">
            <p>Company</p>
            <ul>
              <li>Careers</li>
              <li>Internship</li>
              <li>Full Time</li>
              <li>Part Time</li>
              <li>More</li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
        <div className="footer-Rights  ">
        <span>©2024 SOURCEQOD. All rights reserved</span>
        <div>
          <a href="/terms" >Terms & Conditions</a>
          <a href="/privacy">Privacy Policy</a>
        </div>
      </div>
</>
  );
};

export default Footer;