import React from 'react'
import './ContactUsSection.css'
import ContactUs from '../ContactUs/ContactUs'
import ContactUsSectionImg from '../../assets/ContactUsSectionImg.webp'
const ContactUsSection = () => {
  return (
    <div className='ContactUsSection  '>
      <img loading="lazy" src={ContactUsSectionImg} alt="" />
      <h2 className='ContactUsSectionHeader'>
      Create something awesome 
      </h2>
      <h2 className='ContactUsSectionHeader '>
      and extraordinary with us
      </h2>
      <p className='ContactUsSectionP'>
      With a lot of creativity, we can make your dream come true
      </p>
      <ContactUs />
    </div>
  )
}

export default ContactUsSection
