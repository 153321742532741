import React, { useState, useEffect } from 'react';
import './Navbar.css';
import Logo2 from '../Logo/Logo';
import ContactUs from '../ContactUs/ContactUs';
import { NavLink, useLocation } from 'react-router-dom';

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    let scrollPosition = 0;
    switch (hash) {
      case '#service':
        scrollPosition = 480;
        break;
      case '#project':
        scrollPosition = 3600;
        break;
      case '#reviews':
        scrollPosition = 6050;
        break;
      default:
        scrollPosition = 0;
    }
    if (scrollPosition !== 0) {
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth'
      });
    }
  }, [location]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleNavClick = (event, section) => {
    event.preventDefault();
    window.location.href = `/#${section}`;
    setIsDropdownOpen(false);
  };

  return (
    <div className='navbar'>
      <Logo2 />
      <nav className={`nav-menu ${isDropdownOpen ? 'open' : ''}`}>                
        <NavLink exact to="/" activeClassName="active" className="nav-item" onClick={() => setIsDropdownOpen(false)}>Home</NavLink>
        <a href="/" className="nav-item" onClick={(e) => handleNavClick(e, 'service')}>Service</a>
        <NavLink to="/about" activeClassName="active" className="nav-item" onClick={() => setIsDropdownOpen(false)}>About</NavLink>
        <NavLink to="/contact" activeClassName="active" className="nav-item" onClick={() => setIsDropdownOpen(false)}>Contact Us</NavLink>
        <a href="/" className="nav-item " onClick={(e) => handleNavClick(e, 'project')}>Projects</a>
      </nav>
      <div className="contact-us-navbar">
        <ContactUs />
      </div>
      <i className="fa-solid fa-bars nav-phone" onClick={toggleDropdown}></i>
    </div>
  );
}

export default Navbar;