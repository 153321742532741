import React, { useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import './Home.css'
import Navbar from '../../components/Navbar/Navbar'
import HeroSection from '../../components/HeroSection/HeroSection'
import Services from '../../components/Services/Services'
import Ideas from '../../components/Ideas/Ideas'
import Footer from '../../components/Footer/Footer'
import ContactUsSection from '../../components/ContactUsSection/ContactUsSection'
// import SliderSection from '../../components/SliderSection/SliderSection'
import PortfolioSection from '../../components/PortfolioSection/PortfolioSection'

const Home = () => {
  const containerRef = useRef(null)
  const [shouldAnimate, setShouldAnimate] = useState(true)

  useEffect(() => {
    // Check if the URL contains "#service"
    if (window.location.hash.includes('#service') || window.location.hash.includes('#project') || window.location.hash.includes('#reviews') || window.location.hash.includes('#home')) {
      setShouldAnimate(false)
      return
    }

    const container = containerRef.current
    
    gsap.set(container.children, { autoAlpha: 0 })
    
    const tl = gsap.timeline()
    
    tl.to(container.children, {
      duration: 1,
      autoAlpha: 1,
      stagger: 0.2,
      delay: 9
    })

    return () => {
      tl.kill()
    }
  }, [])

  return (
    <>
      <Navbar />
      <HeroSection />
      <div ref={containerRef} style={shouldAnimate ? {} : { opacity: 1, visibility: 'visible' }}>
        <Services />
        <Ideas />
        <PortfolioSection />
        {/* <SliderSection /> */}
        <ContactUsSection />
        <Footer /> 
      </div>
    </>
  )
}

export default Home