import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';

const SourceqodAnimation = ({ shouldAnimate }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (!shouldAnimate) return;

    const text = "SOURCEQOD";
    const container = containerRef.current;

    container.innerHTML = '';

    const spans = text.split('').map(letter => {
      const span = document.createElement('span');
      span.textContent = letter;
      span.style.opacity = 0;
      span.style.display = 'inline-block';
      container.appendChild(span);
      return span;
    });

    const tl = gsap.timeline();
    tl.to(spans, {
      opacity: 1,
      duration: 0.5,
      stagger: {
        each: 0.1,
        from: "start"
      },
      ease: "power2.out"
    });

    tl.to(spans, {
      opacity: 0,
      duration: 1.5,
      stagger: {
        each: 0.1,
        from: "start"
      },
      ease: "power2.in",
      delay: 0
    });

    return () => {
      tl.kill();
    };
  }, [shouldAnimate]);

  return (
    <div
      ref={containerRef}
      style={{
        fontSize: 'clamp(36px, 10vw, 72px)', // Responsive font size
        fontWeight: 'bold',
        color: 'white',
      }}
    />
  );
};

const LogoAnimation = () => {
  const containerRef = useRef(null);
  const backgroundRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  const [shouldAnimate, setShouldAnimate] = useState(true);

  useEffect(() => {
    // Check if the URL contains "#service"
    if (window.location.hash.includes('#service') || window.location.hash.includes('#project') || window.location.hash.includes('#reviews')) {
      setShouldAnimate(false);
      setIsVisible(false);
      return;
    }

    const container = containerRef.current;
    const background = backgroundRef.current;

    const tl = gsap.timeline({
      onComplete: () => {
        setIsVisible(false);
      }
    });

    // Set initial state
    gsap.set(container, { opacity: 0 });
    gsap.set(background, { opacity: 1 });

    // Fade in the content
    tl.to(container, {
      opacity: 1,
      duration: 3,
      delay: 0
    });

    // Fade out the content
    tl.to(container, {
      opacity: 0,
      duration: 1,
      delay: 0
    });

    // Fade out the background at the very end
    tl.to(background, {
      opacity: 0,
      duration: 1,
    });

    return () => {
      tl.kill();
    };
  }, []);

  if (!isVisible) return null;

  return (
    <>
      <div 
        ref={backgroundRef}
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          backgroundColor: 'black',
          zIndex: 111111111111110,
        }}
      />
      <div 
          className="customlogofont"
        ref={containerRef}
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          margin: 0,
          // fontFamily: 'font5',
          zIndex: 111111111111111,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          opacity: 0
        }}
      >
        <SourceqodAnimation shouldAnimate={shouldAnimate} />
      </div>
    </>
  );
};

export default LogoAnimation;