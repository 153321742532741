import React, { useState } from 'react';
import './ContactForm.css';
import Modal from '../../components/Modal/Modal'

const ContactTeam = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    jobTitle: '',
    workEmail: '',
    phone: '',
    message: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const handleSendEmail = async () => {
    setIsLoading(true);
    setError(null);
    setSuccessMessage('');
    try {
      const response = await fetch('http://localhost:5001/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      const data = await response.json();
      setSuccessMessage(data.message);
      // Clear form data after successful submission
      setFormData({
        firstName: '',
        lastName: '',
        jobTitle: '',
        workEmail: '',
        phone: '',
        message: ''
      });
    } catch (error) {
      console.error('Error sending email:', error);
      setError('Failed to send email. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenWhatsApp = () => {
    const phoneNumber = '916238437747'; // Remove spaces and '+' sign
    const message = encodeURIComponent(`
      Name: ${formData.firstName} ${formData.lastName}
      Job Title: ${formData.jobTitle}
      Work Email: ${formData.workEmail}
      Phone: ${formData.phone}
      Message: ${formData.message || 'Hello, I would like to get in touch with your team.'}
    `);
    window.open(`https://wa.me/${phoneNumber}?text=${message}`, '_blank');
  };

  return (
    <div className="ContactForm Container">
      <div className="form-section">
        <h3>Chat to our team</h3>
        <p>Need help with something? Want a demo? Get in touch with our friendly team and we'll get in touch within 2 hours.</p>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input type="text" name="firstName" placeholder="First name" value={formData.firstName} onChange={handleInputChange} required />
            <input type="text" name="lastName" placeholder="Last name" value={formData.lastName} onChange={handleInputChange} required />
          </div>
          <input type="text" name="jobTitle" placeholder="Job title" value={formData.jobTitle} onChange={handleInputChange} required />
          <input type="email" name="workEmail" placeholder="Work email" value={formData.workEmail} onChange={handleInputChange} required />
          <div className="phone-input">
            <input type="tel" name="phone" placeholder="+1 (555) 000-0000" value={formData.phone} onChange={handleInputChange} required />
          </div>
          <button type="submit" className='submit'>Get in touch</button>
        </form>
      </div>
      <div className="promo-section">
        <div className="promo-image"></div>
        <div className="promo-section-content">
          <div className="promo-text">
            <p className='promo-text-info'>Discover our comprehensive suite of digital solutions. From bespoke web and app development to cutting-edge SaaS solutions, immersive game creation, and impactful branding strategies, we specialize in delivering tailored solutions to enhance your digital presence and drive business growth.</p>
            <div className="promo-info">
              <div className="">
                <p className='CEO-Name'>Maya Rothwell</p>
                <p className='PROFESSION'>Founder & CEO</p>
              </div>
              <p className="company">SOURCEQOD</p>
            </div>
          </div>
        </div>
      </div>   
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="modal-input">
          <input 
            type="text" 
            name="message"
            placeholder="Say hello to our expert 👋" 
            value={formData.message}
            onChange={handleInputChange}
          />
          <button 
            className="modal-email-button" 
            onClick={handleSendEmail}
            disabled={isLoading}
          >
            <i className="fa-regular fa-envelope"></i> 
            {isLoading ? 'Sending...' : 'Send Email'}
          </button>
        </div>
        <div className="modal-input">
        <input 
            type="text" 
            name="message"
            placeholder="Say hello to our expert 👋" 
            value={formData.message}
            onChange={handleInputChange}
          />
          <button className="fa-whatsapp-btn" onClick={handleOpenWhatsApp}>
            <i className="fa-brands fa-whatsapp"></i> WhatsApp
          </button>
        </div>
        {error && <p className="error-message">{error}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
      </Modal>
    </div>
  );
};

export default ContactTeam;