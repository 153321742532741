import React, { useState, useRef, useEffect } from 'react';
import './Slider.css';
import img12112 from '../../assets/hiuefdskl.webp'
import img121122 from '../../assets/pexels-fauxels-3183183.webp'
import img1211222 from '../../assets/pexels-cottonbro-4709289.webp'
import img1211221 from '../../assets/pexels-goumbik-574070.webp'

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [startX, setStartX] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false);
  const sliderRef = useRef(null);

  const slides = [
    { image: img12112 },
    { image: img121122 },
    { image: img1211222 },
    { image: img1211221 },

  ];

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
    setIsSwiping(true);
  };

  const handleTouchMove = (e) => {
    if (!isSwiping) return;
    const x = e.touches[0].clientX;
    const diff = startX - x;
    if (Math.abs(diff) > 50) {
      if (diff > 0) {
        nextSlide();
      } else {
        prevSlide();
      }
      setIsSwiping(false);
    }
  };

  const handleTouchEnd = () => {
    setIsSwiping(false);
  };

  const handleMouseDown = (e) => {
    setStartX(e.clientX);
    setIsSwiping(true);
  };

  const handleMouseMove = (e) => {
    if (!isSwiping) return;
    const x = e.clientX;
    const diff = startX - x;
    if (Math.abs(diff) > 50) {
      if (diff > 0) {
        nextSlide();
      } else {
        prevSlide();
      }
      setIsSwiping(false);
    }
  };

  const handleMouseUp = () => {
    setIsSwiping(false);
  };

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  useEffect(() => {
    const slider = sliderRef.current;
    slider.addEventListener('mousemove', handleMouseMove);
    slider.addEventListener('mouseup', handleMouseUp);
    slider.addEventListener('mouseleave', handleMouseUp);
    return () => {
      slider.removeEventListener('mousemove', handleMouseMove);
      slider.removeEventListener('mouseup', handleMouseUp);
      slider.removeEventListener('mouseleave', handleMouseUp);
    };
  }, [isSwiping, startX]);

  return (
    <div className="about-us-section Container2">
      <div className="about-us-content">
        <p className='about-us-p'>About Us</p>
        <p className='about-us-content-p'>Leading digital agency with solid design and development expertise. We build readymade websites, mobile applications, and elaborate online business services.</p>
      </div>
      <div className="slider" ref={sliderRef}
           onTouchStart={handleTouchStart}
           onTouchMove={handleTouchMove}
           onTouchEnd={handleTouchEnd}
           onMouseDown={handleMouseDown}>
        <div className="slider-container" style={{transform: `translateX(-${currentSlide * 65}%)`}}>
          {slides.map((slide, index) => (
            <div key={index} className="slide">
              <div className="slide-image">
                <img loading="lazy" src={slide.image} alt={`Slide ${index + 1}`} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Slider;