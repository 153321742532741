import React from "react";
import "./Ideas.css";
import SectionName from "../SectionName/SectionName";
import Explore from "../Explore/Explore";
import ExpertiseImg from "../../assets/lap.webp";
import Ser from "../../assets/button-R2.webp";
import Ser2 from "../../assets/ser2.webp";
const sectionNames2 = [ 'WHY US'];

const StrategyCard = ({ title, description, steps }) => (
  <div className="card">
    <h2>{title}</h2>
    <p>{description}</p>
    <button>Learn more</button>
    <ul>
      {steps.map((step, index) => (
        <li key={index}>
          <span className="step-number">{index + 1}</span>
          <span className="step-text">{step}</span>
        </li>
      ))}
    </ul>
  </div>
);

const TaskList = ({ tasks }) => (
  <div className="task-list">
    <h3>SaaS Project</h3>
    <p>Priority Tasks</p>
    <ul>
      {tasks.map((task, index) => (
        <li key={index}>
          <input type="checkbox" checked={task.completed} readOnly />
          <span>{task.name}</span>
        </li>
      ))}
    </ul>
  </div>
);

const App = () => {
  const customizedStrategies = {
    title: "Customized Strategies",
    description:
      "Our team takes the time to understand your brand, target audience, and objectives, allowing us to develop customized digital marketing plans that drive tangible results.",
    steps: [
      "Research and Careful Planning",
      "Attractive and Responsive Design",
      "SEO Optimization and Performance Analysis",
    ],
  };

  const collaborativePartnership = {
    title: "Collaborative Partnership",
    description:
      "We listen to your ideas, provide transparent progress updates, and actively involve you in the decision-making process. Our goal is to build a strong and long-lasting partnership.",
    tasks: [
      { name: "Meeting with client", completed: true },
      { name: "Design review with stakeholders", completed: false },
      { name: "Design for landing page", completed: false },
    ],
  };

  return (
    <div className="app">
      <StrategyCard {...customizedStrategies} />
      <div className="partnership-card">
        <h2>{collaborativePartnership.title}</h2>
        <p>{collaborativePartnership.description}</p>
        <button>Learn more</button>
        <TaskList tasks={collaborativePartnership.tasks} />
      </div>
    </div>
  );
};

const Ideas = () => {
  return (
    <>
      <div className="Ideas-Container Container Container2">
      <SectionName sectionNames={sectionNames2} />
      <div className="Ideas-Container-Header">
          <h2 className="Ideas-Container-Header-Normal">Transforming Your Ideas</h2>
          <h2 className="Ideas-Container-Header-Normal">into Extraordinary Realities</h2>
          <h2 className="Ideas-Container-Header-Phone">Transforming Your Ideas into Extraordinary Realities</h2>
        </div>
      </div>
      <div className="Ideas-Container Container Container2">
        <div className="Expertise-Container">
          <div className="Expertise-Container-Left">
            <h3>Expertise and Innovation</h3>
            <p>
              Our team consists of highly skilled professionals who stay updated
              with the latest industry trends, tools, and technologies.
            </p>
            <Explore buttonText="Learn More" />
            </div>
          <div className="Expertise-Container-Right">
            <img loading="lazy" src={ExpertiseImg} alt="" />
          </div>
        </div>
      </div>
      <div className="Expertise-Container-Bottom Container">
        <div className="Expertise-Container-Bottom-Box">
          <h3>Customized Strategies</h3>
          <p>
            Our team takes the time to understand your brand, target audience,
            and objectives, allowing us to develop customized digital marketing
            plans that drive tangible results.
          </p>
          <Explore buttonText="Learn More" />
          <img loading="lazy" src={Ser} alt="" />
        </div>
        <div className="Expertise-Container-Bottom-Box">
          <h3>Collaborative Partnership</h3>
          <p>
            We listen to your ideas, provide transparent progress updates, and
            actively involve you in the decision-making process. Our goal is to
            build a strong and long-lasting partnership.
          </p>
          <Explore buttonText="Learn More" />
          <img loading="lazy" src={Ser2} alt="" />
        </div>
      </div>
    </>
  );
};

export default Ideas;
