import React, { useState, useEffect } from 'react';
import './BlogCards.css';

const BlogCards = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    // Generate mock data
    const generateMockData = () => {
      const mockPosts = [];
      for (let i = 1; i <= 5; i++) {
        mockPosts.push({
          id: i,
          title: `How To Create A Successful Blog ${i}`,
          picture: `https://picsum.photos/id/${i + 10}/300/200`,
          date: `September 15, 2023`,
          content: `This is the content of blog post This is the content of blog post ${i}. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`
        });
      }
      setPosts(mockPosts);
    };

    generateMockData();
  }, []);

  return (
    <div className='blog-post-container Container2 Container'>
      {posts.map((post) => (
        <div key={post.id} className="blog-post-includes">
          <img loading="lazy" src={post.picture} alt={post.title} className="post-img-includes" />
          <div className="post-title-includes">
            <p className="post-title-p-includes">{post.title}</p>
            <p className="post-content-date">{post.date}</p>
            <p className="post-content-preview">{post.content.substring(0, 120)}...</p>
            <a href={`/blog/post/${post.id}/${post.title}`} className="read-more-btn">
              Read More
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlogCards;