import React from 'react'
import Slider from '../../components/Slider/Slider'
import WhatWereGoodAt from '../../components/WhatWereGoodAt/WhatWereGoodAt'
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'

const AboutUsPage = () => {
  return (
    <>
      <Navbar />
      <Slider />
      <WhatWereGoodAt />
      <Footer />
    </>
  )
}

export default AboutUsPage
