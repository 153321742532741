import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './ContactUs.css';

const ContactUs = () => {
  return (
    <Link to="/contact" className='ContactUSBtn'> {/* Use Link instead of button */}
      Contact us
    </Link>
  );
};

export default ContactUs;
