import React from 'react';
import './Explore.css';
import { Link } from 'react-router-dom';

const Explore = ({ buttonText }) => {function handleNavigation(url) {
  window.location.href = url;
}
  return (  
    <button  className="explore-button" onClick={() => handleNavigation('/about')}>{buttonText}      </button>   
  );
};

export default Explore;
