import './App.css';
import Home from './pages/Home/Home';
import AgencyWebsite from './pages/AboutUsPage/AboutUsPage';
import ContactUsPages from './pages/ContactUsPages/ContactUsPages';
import Blog from './pages/Blog/Blog';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AgencyWebsite />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<ContactUsPages />} />
      </Routes>
    </Router>
  );
}

export default App;
