import React from 'react';
import './SectionNameLight.css';

const SectionNameLight = ({ SectionNamesLight }) => {
  return (
    <div className='SectionNameLight'>
      {SectionNamesLight.map((name, index) => (
        <div key={index} className='section-item'>
          {name}
        </div>
      ))}
    </div>
  );
}

export default SectionNameLight;
