import React from 'react';
import './WhatWereGoodAt.css';
import WhatWereGoodAtImg from '../../assets/original-ae91311c2756a230777d58e58e6ca3c8.webp';

const SkillItem = ({ number, title, description, isUiUx = false }) => (
  <div className={`skillItem ${isUiUx ? 'uiuxcolor' : ''}`}>
     <div><div className='skillItemHeader'>
      <div className="skillNumber">{number}</div>
      <h3 className="skillTitle">{title}</h3>
    </div>
    <p className="skillDescription">{description}</p>
   </div>
    {isUiUx ? (
      <img loading="lazy" className='skill-img' src={WhatWereGoodAtImg} alt="" />
    ) : (
      <button className="arrow-button">
        <span className="arrow"><i className="fa-solid fa-arrow-right"></i></span>
      </button>
    )}
  </div>
);

const WhatWereGoodAt = () => {
  const skills = [
    {
      number: '01',
      title: 'UI/UX Design',
      description: 'User-Centric Approach: We believe that design should revolve around the needs and desires of your users.',
      isUiUx: true
    },
    {
      number: '02',
      title: 'Web Design',
      description: 'Cutting-edge technology: We stay at the forefront of Web Design, constantly exploring new tools and techniques'
    },
    {
      number: '03',
      title: 'Saas Development',
      description: 'We specialize in SaaS development, offering tailored solutions that empower businesses to streamline operations and enhance productivity'
    },
    {
      number: '04',
      title: 'Mobile App Development',
      description: 'we excel in mobile app development, creating innovative solutions that cater to diverse user needs across platforms'
    },
    {
      number: '05',
      title: 'Game Development',
      description: 'Transform your ideas into immersive gaming experiences with our expert game development services. From concept to deployment, we specialize in creating engaging games that captivate players and push technological boundaries.'
    },
    {
      number: '06',
      title: 'Branding Solutions',
      description: 'Elevate your brand with our comprehensive branding solutions tailored to enhance visibility and engagement. We offer expert logo design, strategic brand naming, and cohesive branding strategies'
    },
  ];

  return (
    <div className="WhatWereGoodAtContainer">
      <div className='WhatWereGoodAtContent'>
        <h1 className="header">What We're Good At</h1>
        <div className="skillList">
          {skills.map(skill => (
            <SkillItem key={skill.number} {...skill} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhatWereGoodAt;