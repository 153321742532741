import React from 'react'
import SectionName from '../SectionName/SectionName'
import ArrowButton from '../ArrowButton/ArrowButton'
import './Services.css'
import Explore from '../Explore/Explore'
import LaptopImg from '../../assets/capability-img - Copy.webp'
const sectionNames = ['SERVICES'];

const Services = () => {
  return (
    <>

    <div className='Services-Container Container Container2'>
      <div className="Services-Left">
      <SectionName sectionNames={sectionNames} />
      <h2>We offer several service for you</h2>
      </div>
      <div className="Services-Right">
        <p className="Services-Container-P">
        The best service we offer to you and we are ready to realize the
        ideas you give us as best as possible.</p>
        <Explore buttonText="Explore our Services" />
        </div>
    </div>
    <div className="Services-Container-Options-Container Container Container2">
    <div className="Services-Container-Options ">
        <div className="Services-Options-Box">
            <p  className="Services-Options-Box-Header-Number">
                01
            </p>
            <p  className="Services-Options-Box-Header">
            Development

            </p>
            <p className="Services-Container-P">
            Our expert team of front-end developers are ready to integrate the beauty
            of Design into the code using the latest frameworks and technologies.
            </p>
        </div>
        <div className="Services-Options-Box Services-Options-Box-2">
          <div className="background-options"></div>
        <p  className="Services-Options-Box-Header-Number">
                02
            </p>
            <p  className="Services-Options-Box-Header">
            Design
            </p>
            <p className="Services-Container-P">
            Get Unique and High Quality UI and UX Design Solutions for Website Interface and Mobile Apps.

            </p>
        </div>
        <div className="Services-Options-Box">
        <p  className="Services-Options-Box-Header-Number">
                03
            </p>
            <p  className="Services-Options-Box-Header">
            Brand Strategy
            </p>
            <p className="Services-Container-P">
            In our strategy process, we analyze customer needs, identify general product problems and advise on important future decisions.

            </p>
        </div>
    </div>
    <div className='LaptopImgDiv'>
      <ArrowButton />
          <img loading="lazy" src={LaptopImg} className='LaptopImg' alt="" />

    </div>
    </div>
    </>


  )
}

export default Services
