import React from 'react';
import './ArrowButton.css'
const ArrowButton = () => {
  return (
    <button className="arrow-button">
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 17l8.5-8.5M17 17V7H7" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </button>
  );
};

export default ArrowButton;