import React, { useEffect, useRef } from 'react'
import Navbar from '../../components/Navbar/Navbar'

import Footer from '../../components/Footer/Footer'
import  ContactForm from '../../components/ContactForm/ContactForm'

const ContactUsPages = () => {


  return (
    <>
      <Navbar />
      <ContactForm />
      <Footer /> 
    </>
  )
}

export default ContactUsPages